<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

//import Stat from "./widget";
//import RevenueAnalytics from "./revenue";
//import SalesAnalytics from "./sales-analytics";
/*import EarningReport from "./earning";
import Sources from "./sources";
import RecentActivity from "./recent-activity";
import RevenueLocation from "./revenue-location";
import Chat from "./chat";
import Transaction from './transaction'; */
import { apiRequest } from "@/helpers/api-call";

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,

    //RevenueAnalytics,
    //SalesAnalytics,
  },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "Dashboard",
        },
        {
          text: "Dashboard",
          active: true,
        },
      ],
      totalVehicule: 0,
      totalVehiculeLouerR: 0,
      totalVehiculeLouerNR: 0,
      totalRecetteLocation: 0,
      totalDepense: 0,
    };
  },
  async mounted() {
    //Chargement des info
    const data = await apiRequest(
      "GET",
      "admin/entreprise/info",
      undefined,
      false
    );
    //console.log("data.data:", data.data);
    if (data && data.data) {
      console.log("data.data:", data.data);
      this.totalVehicule = data.data.totalVehicule;
      this.totalVehiculeLouerR = data.data.totalVehiculeLouerR;
      this.totalVehiculeLouerNR = data.data.totalVehiculeLouerNR;
      this.totalRecetteLocation = data.data.totalRecetteLocation;
      this.info.totalDepense = data.data.totalDepense;
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12">
        <div class="row">
          <div class="col-md-12">
            <b-card
              header-class="bg-transparent border-primary"
              class="border border-secondary"
            >
              <template v-slot:header>
                <h5 class="my-0">
                  Nombre total de véhicules
                </h5>
              </template>
              <div class=" float-right">
                <h1 class="text-primary">{{ totalVehicule }}</h1>
              </div>
            </b-card>
          </div>
          <div class="col-md-6">
            <b-card
              header-class="bg-transparent border-primary"
              class="border border-warning"
            >
              <template v-slot:header>
                <h5 class="my-0">
                  Véhicules en location sur RMobility
                </h5>
              </template>
              <div class=" float-right">
                <h1 class="text-primary">{{ totalVehiculeLouerR }}</h1>
              </div>
            </b-card>
          </div>
          <div class="col-md-6">
            <b-card
              header-class="bg-transparent border-primary"
              class="border border-success"
            >
              <template v-slot:header>
                <h5 class="my-0">
                  Véhicules en location en agence
                </h5>
              </template>
              <div class=" float-right">
                <h1 class="text-primary">{{ totalVehiculeLouerNR }}</h1>
              </div>
            </b-card>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <b-card
              header-class="bg-transparent border-primary"
              class="border border-dark"
            >
              <template v-slot:header>
                <h5 class="my-0">
                  Recette totale des locations
                </h5>
              </template>
              <div class=" float-right">
                <h1 class="text-primary">{{ totalRecetteLocation }} FCFA</h1>
              </div>
            </b-card>
          </div>
          <div class="col-md-6">
            <b-card
              header-class="bg-transparent border-primary"
              class="border border-info"
            >
              <template v-slot:header>
                <h5 class="my-0">Dépenses totales</h5>
              </template>
              <div class=" float-right">
                <h1 class="text-primary">{{ totalDepense }} FCFA</h1>
              </div>
            </b-card>
          </div>
          <!-- <div class="col-md-4">
            <b-card
              header-class="bg-transparent border-primary"
              class="border border-danger"
            >
              <template v-slot:header>
                <h5 class="my-0">
                  Trajets annulés
                </h5>
              </template>
              <div class=" float-right">
                <h1 class="text-primary">{{ this.info.trajetsAnnule }}</h1>
              </div>
            </b-card>
          </div> -->
          <!-- <div class="col-md-4">
            <b-card
              header-class="bg-transparent border-primary"
              class="border border-danger"
            >
              <template v-slot:header>
                <h5 class="my-0">
                  Nombre Total de km
                </h5>
              </template>
              <div class=" float-right">
                <h1 class="text-primary">{{ this.info.distance }}</h1>
              </div>
            </b-card>
          </div> -->
        </div>
        <!-- <RevenueAnalytics /> -->
      </div>
      <!--<div class="col-xl-4">
        <SalesAnalytics />
      </div>-->
    </div>
  </Layout>
</template>
